<template>
  <div
    v-if="!$vuetify.breakpoint.md"
    :class="`${left ? '' : 'ml-auto'} ${
      centered ? 'd-flex justify-center mx-auto' : ''
    } font-weight-bold pa-7 ${colors ? '' : 'features-block'}  ${
      $vuetify.breakpoint.mobile ? 'mx-auto px-3' : 'mr-0'
    } ${customBlockClass}`"
    :style="`${centered ? 'max-width:300px;' : ''}`"
  >
    <div
      :class="`d-flex flex-column ${
        centered ? 'align-center justify-center' : 'align-end'
      } mx-auto`"
    >
      <span class="features-block features">
        {{ $ml.get("feature_list_label_1") }}
      </span>
      <span class="features-block features">
        {{ $ml.get("feature_list_label_2") }}
      </span>
      <span class="features-block features">
        {{ $ml.get("feature_list_label_3") }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "LoginFeaturesList",

  props: {
    centered: { type: Boolean, default: false },
    colors: { type: Boolean, default: false },
    customBlockClass: { type: String, default: "" },
    customFeatureClass: { type: String, default: "" },
    left: { type: Boolean, default: false },
  },

  data() {
    return {};
  },

  computed: {},
};
</script>
<style>
.features {
  color: white;
  opacity: 0.9;
  text-align: center;
  margin-bottom: 7px;
  font-size: 20px;
  padding: 5px 15px;
  display: block;
}

@media (max-width: 700px) {
  .features {
    margin-bottom: 5px;
    font-size: 18px;
    padding: 3px 10px;
  }
}

.features-block {
  background-color: #517abd8e;
  margin-bottom: 25px;
}
</style>
