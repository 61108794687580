<template>
  <v-card
    flat
    tile
    color="transparent"
    class="text-wrap break-word login-slogan-text font-weight-black normal-line-height white--text px-12 mx-5 py-7"
    width="340"
  >
    <div v-html="$ml.get('slogan')"></div>
  </v-card>
</template>

<script>
export default {
  name: "SloganDesktopLogin",
};
</script>

<style>
.login-text-spotlight {
  color: var(--v-accent-base);
  background-color: rgba(81, 123, 189);
  padding: 0px;
  opacity: 0.8;
}

.login-slogan-text {
  font-size: 38px;
}
</style>
