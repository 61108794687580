<template>
  <v-footer :color="'transparent'">
    <v-card-text
      :class="`text-center caption ${
        isMobile
          ? 'login-footer-mobile white--text'
          : 'secondary--text text--lighten-2'
      }`"
    >
      {{ $ml.get("agreement_label") }}
      <a
        :class="`primary--text font-weight-bold px-1 py-0 ${
          isMobile ? 'accent--text' : 'primary--text'
        } text-wrap break-word`"
        href="https://conectasuite.com/termos-de-uso"
        target="_blank"
      >
        {{ $ml.get("terms_of_use") }}
      </a>
      e
      <a
        :class="`primary--text font-weight-bold px-1 py-0 ${
          isMobile ? 'accent--text' : 'primary--text'
        } text-wrap break-word`"
        href="https://conectasuite.com/politica-de-privacidade"
        target="_blank"
      >
        {{ $ml.get("privacy_policy") }}
      </a>
    </v-card-text>
  </v-footer>
</template>
<script>
export default {
  name: "LoginFooter",

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>
<style>
.login-footer-mobile {
  background-color: rgba(81, 123, 189, 0.6);
}
</style>
