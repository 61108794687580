<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="isMobile ? 'white' : 'primary--text'"
        class="text-none caption px-4"
        text
        x-small
        v-bind="attrs"
        v-on="on"
      >
        {{ currentLanguage }}
        <v-icon v-text="'mdi-menu-down'" small class="ml-1" />
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item
        v-for="(item, index) in allLanguages"
        :key="index"
        class="translate-item"
        @click="changeLanguage(item.lang)"
      >
        <v-list-item-title class="py-0">
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "SelectLanguage",

  computed: {
    ...mapGetters(["currentLanguage", "allLanguages"]),

    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },

  methods: {
    ...mapMutations(["setLanguage"]),

    changeLanguage(lang) {
      this.$ml.change(lang);
      this.setLanguage(lang);
    },
  },
};
</script>
