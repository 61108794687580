<template>
  <v-btn
    :color="'accent'"
    height="48"
    :width="$vuetify.breakpoint.xs ? 280 : 340"
    :max-width="$vuetify.breakpoint.xs ? 280 : 340"
    dark
    class="font-weight-bold text-none d-flex align-center justify-center text-body-1 white--text"
    :href="controlWorkspaceURL()"
    target="_blank"
  >
    {{ $ml.get("try_for_free") }}
  </v-btn>
</template>

<script>
import { WORKSPACE_APP_URL_BY_ENV } from "@/helpers/variables";
export default {
  name: "TryForFreeButton",

  methods: {
    controlWorkspaceURL() {
      const environment = process.env.NODE_ENV;
      return WORKSPACE_APP_URL_BY_ENV[environment];
    },
  },
};
</script>
