<template>
  <a
    :class="`caption primary--text font-weight-bold px-1 py-0 ${
      isMobile ? 'accent--text' : 'primary--text'
    } text-wrap break-word`"
    :href="frequentlyAskedQuestions"
    target="_blank"
  >
    {{ $ml.get("help") }}
  </a>
</template>

<script>
import { frequentlyAskedQuestions } from "@/helpers/links";
export default {
  name: "FAQButton",
  data: () => ({
    frequentlyAskedQuestions,
  }),

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>
