<template>
  <v-overlay absolute :value="show" opacity="0.6">
    <v-card
      flat
      color="grey lighten-3"
      class="pa-5 text-center ma-4"
      max-width="440px"
    >
      <v-card-subtitle
        class="mb-5 text-h6 title font-weight-medium text-center black--text"
      >
        {{ $ml.get("marketplace_install") }}
      </v-card-subtitle>

      <v-btn
        :color="'primary'"
        height="48"
        :width="$vuetify.breakpoint.xs ? 280 : 340"
        :max-width="$vuetify.breakpoint.xs ? 280 : 340"
        dark
        class="font-weight-bold text-none text-body-1 white--text"
        :href="`https://workspace.google.com/marketplace/app/conecta_suite/${controlAppId}`"
        target="_blank"
        @click="$emit('close')"
      >
        {{ $ml.get("go_to_marketplace") }}
      </v-btn>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  name: "MarketplaceDialog",
  props: {
    show: Boolean,
  },

  data() {
    return {};
  },
  computed: {
    controlAppId() {
      return process.env.VUE_APP_MARKETPLACE_APP_ID;
    },
  },
};
</script>

<style></style>
